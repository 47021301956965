import Vue from "vue";
import Vuex from "vuex";
import i18n from "@/lang";

import { getToken } from "@/utils/token";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogin: !!getToken() || false, //是否登陆
        userInfo: localStorage.getItem("userInfo") || null, //个人资料
        navsList: [], //导航栏
        navsVal: false, //是否加载完成-导航栏
        message: 0, //未读消息数量
        accountMoney: null, //账户钱
        baseInfo: null, //基础信息
        sms: false,
        isBankLength: false,
        languages: [
            { type: 'zh', id: '1' },
            { type: 'hk', id: '2' },
            { type: 'en', id: '3' },
            { type: 'th', id: '4' }
        ],
        langTypeVal: '', //翻译
        regAuth: null,
        hotsGameList: null,
        registerInfo: null
    },
    getters: {},
    mutations: {
        setHasLogin(state, val) {
            state.isLogin = val;
        },
        setUserInfo(state, val) {
            state.userInfo = val;
        },
        setNavsList(state, val) {
            state.navsList = val;
        },
        setNavsVal(state, val) {
            state.navsVal = val;
        },
        setMessage(state, val) {
            state.message = val;
        },
        setAccountMoney(state, val) {
            state.accountMoney = val;
        },
        setBaseInfo(state, val) {
            state.baseInfo = val;
        },
        setSms(state, val) {
            state.sms = val;
        },
        setIsBankLength(state, val) {
            state.isBankLength = val;
        },
        setRegAuth(state, val) {
            state.regAuth = val;
        },
        setHotsGameList(state, val) {
            state.hotsGameList = val;
        },
        setRegisterInfo(state, val) {
            state.registerInfo = val;
        },
        setLangTypeVal(state, val) {
            state.langTypeVal = val;
        }
    },
    actions: {
        setUserInfoAc(context, val) {
            context.commit("setUserInfo", val);
        },
        setHasLoginAc(context, val) {
            context.commit("setHasLogin", val);
        },
        setNavsListAc(context, val) {
            context.commit("setNavsList", val);
        },
        setNavsValAc(context, val) {
            context.commit("setNavsVal", val);
        },
        setMessageAc(context, val) {
            context.commit("setMessage", val);
        },
        setAccountMoneyAc(context, val) {
            context.commit("setAccountMoney", val);
        },
        setBaseInfoAC(context, val) {
            context.commit("setBaseInfo", val);
        },
        setSmsAc(context, val) {
            context.commit("setSms", val);
        },
        setIsBankLengthAc(context, val) {
            context.commit("setIsBankLength", val);
        },
        setRegAuthAc(context, val) {
            context.commit("setRegAuth", val);
        },
        setHotsGameListAc(context, val) {
            context.commit("setHotsGameList", val);
        },
        setRegisterInfoAc(context, val) {
            context.commit("setRegisterInfo", val);
        },
        setLangTypeValAc(context, val) {
            context.commit("setLangTypeVal", val);
        },
    },
    modules: {},
});